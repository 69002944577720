import React, { useState } from "react";


const IndividualRegistrationInstructions = () => {
    const [isFullDataVisible, setIsFullDataVisible] = useState(true);
    const [isActive, setIsActive] = useState(false);

    const toggleFullDataVisibility = () => {
        setIsFullDataVisible(!isFullDataVisible);
        setIsActive(!isActive);
    };
    return (
        <>
            <img className="banner-cloud" src="/img/new-home/cloud-banner-common.png" alt="banner" />
            <div className="reg-data">
                <div
                    className={`mobile-heading ${isActive ? 'active' : ''}`}
                    onClick={toggleFullDataVisibility}
                >
                    <h3>Instructions for Practido Online Olympiad:</h3>
                </div>
                {isFullDataVisible && (
                    <div className="full-data show">
                        <ol className="order-list-data">
                            <li>Ensure all personal details (name, class, school) are correct during registration. These will appear on certificates.</li>
                            <li>Ensure your child has a <span className="bold">laptop</span>, or <span className="bold">desktop</span> with a functioning web camera and microphone, and a stable internet connection.</li>
                            <li>Any cheating detected will lead to disqualification. The decision of the jury is final.</li>
                            <li>Attempt as many questions correctly as possible within <span className="bold">50 minutes</span>.</li>
                            <li>There are <span className="bold">no negative marking</span> for incorrect answers.</li>
                            <li>This Olympiad is <span className="bold">Online mode</span>, the exam can't be taken using Mobile device.</li>
                            <li>Do read the <a href="/faqlanding">Frequently Asked Questions (FAQs)</a > thoroughly</li>
                        </ol>
                        <h3>Fees and Payment Details</h3>
                        <ul className="bullet-list-data mgt0">
                            <li className="bold">Participation Fee:</li>
                            <ul className="bullet-list-data mgt0 mgb0">
                                <li><span className="bold">Rs. 99</span> for one subject or <span className="bold">Rs. 199</span> for all three subjects <span className="bold">(Maths, Science, English)</span> for Indian students.</li>
                                <li><span className="bold">$5</span> for one subject or <span className="bold">$10</span> for all three subjects <span className="bold">(Maths, Science, English)</span> for international students.</li>
                            </ul>
                            <li><span className="bold">Payment Policy:</span> The fee is non-refundable once paid.</li>
                        </ul>
                        <h3>Important Dates</h3>
                        <ul className="bullet-list-data mgt0">
                            <li>The Practido Online Olympiad dates will be announced in <span className="bold">October 2024</span> onwards.</li>
                            <li>Registration starts from <span className="bold">August 2024 to September 2024</span>.</li>
                        </ul>
                        <h3>Contact Information</h3>
                        <p>For assistance, please contact our customer support at <a href="mailto:olympiadpractice@naskay.in">olympiadpractice@naskay.in</a>.</p>

                    </div>
                )}
            </div>
        </>

    )
}

export default IndividualRegistrationInstructions