import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { deployment } from "./envConfig";

const firebaseConfig =
  deployment === "production"
    ? {
        apiKey: "AIzaSyDvL_xP6uAiBKhsWen7WApQC1qVdkGC4ow",
        authDomain: "naskay-test.firebaseapp.com",
        projectId: "naskay-test",
        storageBucket: "naskay-test.appspot.com",
        messagingSenderId: "221453283973",
        appId: "1:221453283973:web:0aa2efde3065e1dc89afec",
        measurementId: "G-H98EEM5XTJ",
      }
    : {
        apiKey: "AIzaSyDvL_xP6uAiBKhsWen7WApQC1qVdkGC4ow",
        authDomain: "naskay-test.firebaseapp.com",
        projectId: "naskay-test",
        storageBucket: "naskay-test.appspot.com",
        messagingSenderId: "221453283973",
        appId: "1:221453283973:web:0aa2efde3065e1dc89afec",
        measurementId: "G-H98EEM5XTJ",
      };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);

export const schoolCollectionRef = collection(db, "schools");
export const parentsCollectionRef = collection(db, "parents");
export const participantsCollectionRef = collection(db, "participants");
export const questionsCollectionRef = collection(db, "questions");
