import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { setDoc, doc, getDoc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import {
  auth,
  db,
  schoolCollectionRef,
} from "../../../../config/firebaseConfig";
import { individualRegisterFormFields } from "../../constants/individualRegisterFormFields";
import { Button, TextInput } from "../../../../components";
import envConfig from "../../../../config/envConfig";
import { validateEmail, validatePhone } from "../../../../assets/js/validation";
import { sendEmail } from "../../../../constant/sendEmail";
import { parentRegisterSuccess } from "../../../../constant/emailTemplates/parentRegisterSuccess";

const IndividualRegisterForm = ({ setShowTabs }) => {
  const { schoolId } = useParams();
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [emailId, setEmailId] = useState("");
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (schoolId || verificationEmailSent) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [setShowTabs, schoolId, verificationEmailSent]);

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const docRef = doc(schoolCollectionRef, schoolId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setSchoolDetails({ ...docSnap.data(), id: docSnap.id });
        } else {
          console.log("No such document!");
          // setError((prev) => ({
          //   ...prev,
          //   school: "No School Found with this url!",
          // }));
          setShowTabs(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    schoolId && fetchSchoolDetails();
  }, [schoolId, setShowTabs]);

  const sendVerificationEmail = async () => {
    const actionSettings = {
      url: `${envConfig?.appUrl}onboarding/step1`,
      handleCodeInApp: true,
    };
    setLoading((prev) => ({ ...prev, resend: true }));
    sendEmailVerification(auth.currentUser, actionSettings)
      .then(() => {
        setVerificationEmailSent(true);
      })
      .catch((error) => {
        console.log(error.message);
        throw error;
      })
      .finally(() => setLoading((prev) => ({ ...prev, resend: false })));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const formData = e.target;
    const name = formData?.parentName?.value;
    const email = formData?.parentEmail?.value;
    const phone = formData?.parentPhone?.value;
    const password = formData?.password?.value;

    //validations
    //validate Email
    if (!validateEmail(email)) {
      setError("Please enter a valid email id.");
      formData.parentEmail.focus();
      return;
    }
    //validate Phone
    if (!validatePhone(phone)) {
      setError("Please enter a valid 10 digit school phone number.");
      formData.parentPhone.focus();
      return;
    }

    // validate password
    if (password.length < 6) {
      setError("Password should be atleast 6 characters long.");
      formData.password.focus();
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, submit: true }));

      const docId = `${name.split(" ")[0]}_${phone?.slice(-4)}_${Math.random()
        .toString(36)
        .slice(-4)}`;

      // 1. create firebase auth with email and password
      await createUserWithEmailAndPassword(auth, email, password);

      // 2. Update user profile with display name
      await updateProfile(auth.currentUser, {
        displayName: name,
      });

      //3. send verification mail to user
      await sendVerificationEmail();

      // 4. add parent data to fireStore
      const parentData = {
        accountType: "parent",
        name: name,
        email: email,
        phone: phone,
        uid: auth.currentUser.uid,
      };
      schoolId && (parentData.schoolId = schoolId);
      await setDoc(doc(db, "parents", docId), parentData);

      // 5. send success email
      const { subject, emailBody } = parentRegisterSuccess({
        parentName: name,
        userId: docId,
      });
      const emailResponse = await sendEmail({
        emailId: email,
        subject,
        emailBody,
      });
      console.log(emailResponse);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading((prev) => ({ ...prev, submit: false }));
    }
  };

  return (
    <>
      {schoolId &&
        (schoolDetails ? (
          <div className="col-span-2 flex items-center justify-center gap-5 my-4">
            <div className="w-24 h-24 bg-gray-400 overflow-hidden flex items-center justify-center rounded-full">
              {schoolDetails?.schoolLogoUrl && (
                <img
                  src={schoolDetails?.schoolLogoUrl}
                  alt="schoolLogo"
                  className="h-full w-full"
                />
              )}
            </div>

            <div className="flex flex-col gap-2">
              <h2 className="text-2xl font-semibold">
                {schoolDetails?.schoolName}
              </h2>
              <p className="text-base">
                {schoolDetails?.schoolAddress.address},{" "}
                {schoolDetails?.schoolAddress.city},{" "}
                {schoolDetails?.schoolAddress.state},{" "}
                {schoolDetails?.schoolAddress.country},{" "}
                {schoolDetails?.schoolAddress.pin}
              </p>
            </div>
          </div>
        ) : (
          <div className="col-span-2 flex items-center justify-center gap-5 my-4">
            <div className="w-24 h-24 bg-gray-400 rounded-full animate-pulse" />
            <div className="flex flex-col gap-2">
              <div className="w-60 h-8 bg-gray-400 rounded-lg animate-pulse" />
              <div className="w-60 h-4 bg-gray-400 rounded-lg animate-pulse" />
              <div className="w-24 h-4 bg-gray-400 rounded-lg animate-pulse" />
            </div>
          </div>
        ))}

      {verificationEmailSent ? (
        <div className="space-y-10">
          <div className="flex items-center justify-center gap-4 w-full bg-green-100 p-2 rounded-xl">
            <img src="/img/success.png" alt="" className="w-12 h-12" />

            <p className="!font-semibold !text-lg text-center">
              Verification Email sent.
            </p>
          </div>
          <div className="space-y-6">
            <h1 className="text-2xl font-bold">Verify your email address</h1>
            <div className="space-y-2">
              <p>
                Please confirm your email address by clicking on the link in the
                email we sent to :{" "}
                <span className="!font-semibold">
                  {emailId.split("@")[0]?.slice(0, 3)}
                  {"•".repeat(emailId.split("@")[0].length - 3)}@
                  {emailId.split("@")[1]}
                </span>
              </p>
            </div>

            <Button
              version="secondary"
              text="Resend Email"
              width="200px"
              handleClick={() => sendVerificationEmail(emailId)}
              loading={loading.resend}
            />
            <p>
              Not the correct email?{" "}
              <span
                className="text-[#009DFF] font-semibold cursor-pointer hover:underline"
                onClick={() => setVerificationEmailSent(false)}
              >
                Back to Register
              </span>
            </p>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="wid-100" ref={formRef}>
          <div className="form-outer-container">
            {individualRegisterFormFields?.map(
              ({ name, label, type, required, placeholder, colSpan }) => {
                return (
                  <div key={name} className={`form-group col-span-${colSpan}`}>
                    <label htmlFor={name}>
                      {label}
                      {required && <span> * </span>}
                    </label>

                    <TextInput
                      type={type}
                      placeholderText={placeholder}
                      inputName={name}
                      isRequired={required}
                      handleChange={
                        name === "parentEmail"
                          ? (e) => setEmailId(e.target.value)
                          : null
                      }
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="flex flex-col gap-4 mt-5 col-md-12">
            <div className="">
              <div className="text-[#f00] text-xs mb-1">{error}</div>
              <Button
                version="secondary"
                text="Submit"
                type="submit"
                loading={loading?.submit}
              />
            </div>
            <div className="text-lg flex gap-2">
              <p className="font-medium">Already Have a Account?</p>
              <Link
                to="/login"
                className="font-bold hover:underline cursor-pointer text-[#009DFF] hover:text-[#009DFF]"
              >
                Sign In
              </Link>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default IndividualRegisterForm;
