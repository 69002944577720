import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  auth,
  participantsCollectionRef,
  schoolCollectionRef,
} from "../../../config/firebaseConfig";
import { getDocs, query, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import envConfig from "../../../config/envConfig";
import html2canvas from "html2canvas";
import { FaClipboardCheck, FaShare } from "react-icons/fa6";
import { IoCopy } from "react-icons/io5";
import { QRCodeSVG } from "qrcode.react";
import { FiDownload } from "react-icons/fi";
import { IoWarningOutline } from "react-icons/io5";
import SchoolLogo from "./SchoolLogo";
import { sendVerificationEmail } from "../dashboardFunctions";
import { LuLoader2 } from "react-icons/lu";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFContent from "./PDFContent";
import DashboardInstructions from "../parent/DashboardIns";

const SchoolDashboard = () => {
  const navigate = useNavigate();
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [registrationCode, setRegistrationCode] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const [loading, setLoading] = useState(null);
  const [verificationEmailSent, setVerificationEmailSent] = useState(null);

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      const uid = auth.currentUser.uid;
      const q = query(schoolCollectionRef, where("uid", "==", uid));

      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          return navigate("/login");
        }
        querySnapshot.forEach((doc) => {
          setSchoolDetails({ ...doc.data(), id: doc.id });
        });
      } catch (error) {
        console.log(error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSchoolDetails();
        setEmailVerified(user?.emailVerified);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Cleanup when component unmounts
  }, [navigate]);

  const [participants, setParticipants] = useState([]);
  useEffect(() => {
    const fetchParticipants = async () => {
      const q = query(
        participantsCollectionRef,
        where("schoolDetails.schoolId", "==", schoolDetails?.id)
      );
      try {
        const participants = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          participants.push({ ...doc.data(), id: doc.id });
        });

        setParticipants(participants);
      } catch (error) {
        console.log(error);
      }
    };

    schoolDetails && fetchParticipants();

    schoolDetails &&
      setRegistrationCode(
        `${envConfig.appUrl}register/individual/${schoolDetails?.id}`
      );
  }, [schoolDetails]);

  // fetch parents with this school link

  const [totalFeeCollected, setTotalFeeCollected] = useState(0);
  useEffect(() => {
    // ------------- fetch total fee form parents dashboard - -------//
    //
    //
    // if (participants?.length) {
    // const paymentArray = participants?.map(
    //   (participant) =>
    //     participant.paymentDetails && participant.paymentDetails
    // );
    // const uniquePaymentArray = paymentArray?.reduce((acc, curr) => {
    //   if (
    //     !acc.some(
    //       (item) => item?.razorpay_payment_id === curr?.razorpay_payment_id
    //     )
    //   ) {
    //     acc.push(curr);
    //   }
    //   return acc;
    // }, []);
    // const totalFeeINR = uniquePaymentArray?.reduce((acc, curr) => {
    //   return curr?.currency === "INR" ? (acc += curr?.amount) : 0;
    // }, 0);
    // const totalFeeUSD = uniquePaymentArray?.reduce((acc, curr) => {
    //   return curr?.currency === "USD" ? (acc += curr?.amount) : 0;
    // }, 0);
    // setTotalFeeCollected({
    //   totalFeeINR,
    //   totalFeeUSD,
    // });
    // }
  }, []);

  const [copied, setCopied] = useState(false);
  const regCodeRef = useRef(null);
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(registrationCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);

      const range = document.createRange();
      range.selectNodeContents(regCodeRef.current);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const shareLink = async () => {};

  const qrRef = useRef();
  const [qrHover, setQrHover] = useState(false);
  const [qrShareLink, setQrShareLink] = useState("");
  const downloadQRCode = useCallback(() => {
    html2canvas(qrRef.current, {
      useCORS: true,
      logging: true,
    }).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `unique-student-registration-qrcode.png`;
      link.click();
    });
  }, [qrRef]);

  const shareQRCode = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "QR Code to Register for Students",
          url: "",
        });
      } catch (error) {
        console.log("Error sharing link: ", error);
      }
    } else {
      console.log("Web Share API not supported");
    }
  };

  return (
    <div className="container space-y-5">
      {!emailVerified && (
        <div className="bg-[#FFDBD2] text-[#FF0707] p-3 flex gap-2 items-center justify-center rounded-xl">
          <IoWarningOutline className="w-5 h-5 " />
          <span className="text-base">
            Please verify your email address to unlock more features and
            services. We've sent a verification link to your registered email.
          </span>
          {verificationEmailSent ? (
            <span className="font-bold">✓ Sent</span>
          ) : (
            <button
              className="font-extrabold hover:underline flex items-center gap-2"
              onClick={() =>
                sendVerificationEmail({
                  actionUrl: "schoolDashboard",
                  setLoading: setLoading,
                  setVerificationEmailSent: setVerificationEmailSent,
                })
              }
            >
              Resend Link
              {loading?.resend && (
                <span>
                  <LuLoader2 className="animate-spin" />
                </span>
              )}
            </button>
          )}
        </div>
      )}
      <div className="school-logo-row flex items-center justify-between">
        <SchoolLogo schoolDetails={schoolDetails} />
        <div className="practido-log">
          <img
            className="banner-cloud w-[360px]"
            src="/img/new-home/cloud-banner-common.png"
            alt="banner"
          />
        </div>
      </div>

      {/* <div>
          <label htmlFor="session">Search Session</label>

          <div className="flex items-end  gap-3">
            <div className="w-[500px]">
              <SelectInput placeholderText="Select Session" />
            </div>
            <Button version="secondary" text="Search Session" width="240px" />
          </div>
        </div> */}
      <div className="relative space-y-4">
        {!emailVerified && (
          <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/50 rounded-xl backdrop-blur-[2px] flex items-center justify-center">
            <span className="text-white font-extrabold text-2xl text-center">
              Verify your Email Id <br />
              to unlock features and services.
            </span>
          </div>
        )}
        <div className="row school-dashbaord-cards">
          {[
            {
              name: "studentsRegistered",
              label: "Total Students Registered",
              value: participants?.length?.toString()?.padStart(2, "0"),
            },
            {
              name: "examinationsRegistered",
              label: "Total Examinations Registered",
              value: participants
                ?.reduce((acc, curr) => (acc += curr?.subjects?.length), 0)
                ?.toString()
                ?.padStart(2, "0"),
            },
            {
              name: "feeCollected",
              label: "Total Fee Collected",
            },
          ]?.map(({ name, label, value }) => (
            <div className="col-md-4">
              <div className="card shadow-md text-[#34495E]" key={name}>
                <h3 className="text-lg font-bold">{label}</h3>
                {name !== "feeCollected" ? (
                  <div className="text-3xl font-bold">{value}</div>
                ) : (
                  <div className="text-3xl font-bold flex gap-2">
                    {totalFeeCollected?.totalFeeINR ? (
                      <span className="block">
                        ₹{" "}
                        {totalFeeCollected?.totalFeeINR
                          ?.toString()
                          ?.padStart(2, "0")}
                      </span>
                    ) : null}
                    {totalFeeCollected?.totalFeeINR &&
                    totalFeeCollected?.totalFeeUSD
                      ? " + "
                      : ""}
                    {totalFeeCollected?.totalFeeUSD ? (
                      <span className="block">
                        ${" "}
                        {totalFeeCollected?.totalFeeUSD
                          ?.toString()
                          ?.padStart(2, "0")}
                      </span>
                    ) : null}
                  </div>
                )}
                {name === "studentsRegistered" && (
                  <Link
                    to="registeredStudents"
                    className="text-right hover:underline font-bold text-[#004EFD]"
                  >
                    View all
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="school-dash-blue-card flex gap-2">
          <div className="col-md-12 col-lg-7 left-blue-card w-full bg-[#DBF2F7] rounded-xl p-4 flex gap-2 justify-center">
            <div className="data flex flex-col justify-evenly">
              <p className="text-[14px]">
                Display this QR code or share the link below to let parents
                register their children for the Olympiad.
              </p>
              <div className="link-tag flex items-center gap-4 justify-between bg-[#BDDEE5] p-2 px-3 rounded-lg">
                <a
                  className="text-base w-full font-semibold text-[#004EFD]"
                  href={registrationCode}
                  target="_blank"
                  rel="noreferrer"
                  ref={regCodeRef}
                >
                  {registrationCode?.slice(0, 40)}
                  {registrationCode?.length > 40 && "..."}
                </a>
                <div className="flex items-center gap-2 icons">
                  <button
                    className={`p-2 px-3 rounded-xl ${
                      copied
                        ? "bg-green-500 hover:bg-green-500/90 text-white"
                        : "bg-white"
                    }`}
                    style={
                      copied
                        ? { boxShadow: "0px 3px 0px rgba(50 150 50)" }
                        : { boxShadow: "0px 3px 0px rgba(156 163 175)" }
                    }
                    onClick={copyLink}
                  >
                    {copied ? (
                      <FaClipboardCheck className="w-4 h-4" />
                    ) : (
                      <IoCopy />
                    )}
                  </button>
                  <button
                    className="bg-white p-2 px-3 rounded-xl"
                    style={{ boxShadow: "0px 3px 0px rgba(156 163 175)" }}
                    onClick={shareLink}
                  >
                    <FaShare />
                  </button>
                </div>
              </div>
              {/* <div className="flex items-center gap-3">
                <span className="text-base">OR</span>
                <button
                  className="bg-white p-2 px-4 rounded-xl"
                  style={{ boxShadow: "0px 3px 0px rgba(156 163 175)" }}
                  onClick={shareQRCode}
                >
                  Share QR Code
                </button>
              </div> */}
            </div>
            <div
              className="img-box relative bg-white rounded-xl border-2 border-gray-300"
              onMouseEnter={() => setQrHover(true)}
              onMouseLeave={() => setQrHover(false)}
            >
              <div
                className={`${
                  qrHover ? "flex" : "hidden"
                } absolute top-0 left-0 w-full h-full bg-black/30 rounded-xl z-10 gap-4 items-center justify-center`}
              >
                <span className="p-2 bg-white/90 rounded-lg cursor-pointer">
                  <FiDownload className="w-6 h-6" onClick={downloadQRCode} />
                </span>
                {/* <span className="p-2.5 bg-white/90 rounded-lg cursor-pointer">
                  <FaShare className="h-5 w-5" onClick={shareQRCode} />
                </span> */}
              </div>
              <div ref={qrRef} className="p-3 svg-box">
                <QRCodeSVG value={registrationCode} size={100} />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-5 w-full bg-[#DBF2F7] rounded-xl p-4 flex flex-col gap-1 justify-center">
            <p className="text-base">
              Download the PDF, which includes the important Olympiad
              instructions. Download and share it with your students!
            </p>
            <button
              className="bg-white p-2 px-4 rounded-xl w-fit"
              style={{ boxShadow: "0px 3px 0px rgba(156 163 175)" }}
            >
              <PDFDownloadLink
                document={
                  <PDFContent
                    registrationCode={registrationCode}
                    schoolDetails={schoolDetails}
                  />
                }
                fileName="Practido-Olympiad-Instructions.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading ..." : "Click here to download the PDF"
                }
              </PDFDownloadLink>
            </button>
          </div>
        </div>
      </div>
      <div>
        <div>{/* other resources */}</div>
        <>
          <DashboardInstructions placement="school" />
        </>
      </div>
    </div>
  );
};

export default SchoolDashboard;
