import React from "react";
import { auth } from "../../../config/firebaseConfig";
import envConfig from "../../../config/envConfig";

const PaymentSuccess = ({ type, referenceNumber }) => {
  return (
    <div className="success-container bg-[#FFE9C8] py-4 rounded-2xl">
      <div className="container">
        <div className="success-wrapper">
          <img src="/img/success-icon.png" alt="success icon" />
          <h3 className="text-xl font-semibold">
            {type === "payment" ? "Payment Successful" : "Details Updated"}
          </h3>
          <div className="font-semibold mb-3">
            {type === "payment" && `Transaction ID - ${referenceNumber}`}
          </div>
          {type !== "payment" ? (
            <p>
              Your Details have been successfully updated for the{" "}
              <span className="bold">
                {envConfig.appFullName} Challenge October 2024 Online
              </span>
              <br />
              Please check{" "}
              <span className="text-[#009DFF]">
                {auth.currentUser.email}
              </span>{" "}
              for the details.
            </p>
          ) : (
            <p>
              Your Details have been successfully updated for the{" "}
              <span className="bold">
                {envConfig.appFullName} Challenge October 2024 Online
              </span>
              <br />
              Please check{" "}
              <span className="text-[#009DFF]">
                {auth.currentUser.email}
              </span>{" "}
              for the details.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
