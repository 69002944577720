import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, SelectInput, TextInput } from "../../../components";
import {
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import {
  participantsCollectionRef,
  auth,
  parentsCollectionRef,
  schoolCollectionRef,
} from "../../../config/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import Cart from "./Cart";
import { MdDelete } from "react-icons/md";
import { ClassOptions, subjectsOptions } from "../constants/options";
import { FaMinus, FaPlus } from "react-icons/fa6";
import Step1Instructions from "./Step1Instructions";
import envConfig from "../../../config/envConfig";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ImSpinner2 } from "react-icons/im";

const Step1 = () => {
  const [loading, setLoading] = useState(false);
  const [userAuth] = useAuthState(auth);
  const [participantArray, setParticipantArray] = useState([
    {
      name: "",
      dateOfBirth: "",
      subjects: ["maths", "science", "english"],
      class: "",
    },
  ]);

  const [parentDetails, setParentDetails] = useState(null);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [country, setCountry] = useState("IN");
  const [acknowledgeChecked, setAcknowledgeChecked] = useState(false);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(
          `https://ipinfo.io/json?token=${envConfig?.ipInfoKey}`
        );
        const data = await response.json();
        const country = data.country;
        setCountry(country);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
        setCountry("IN");
      }
    };

    fetchCountry();
  }, []);

  useEffect(() => {
    const fetchParentDetails = async () => {
      const q = query(parentsCollectionRef, where("uid", "==", userAuth?.uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setParentDetails({ ...doc.data(), id: doc.id });
      });
    };
    userAuth && fetchParentDetails();
  }, [userAuth]);

  useEffect(() => {
    const schoolId = parentDetails?.schoolId;
    const fetchSchoolData = async () => {
      const schoolData = await getDoc(doc(schoolCollectionRef, schoolId));
      setSchoolDetails({
        schoolId: schoolData.id,
        schoolName: schoolData.data().schoolName,
        schoolAddress: schoolData.data().schoolAddress,
        schoolLogoUrl: schoolData.data().schoolLogoUrl,
      });
    };

    schoolId && fetchSchoolData();
  }, [parentDetails]);

  const uploadParticipant = async (participant) => {
    const enrollmentNo = `${participant?.name?.split(" ")[0]}_${
      participant?.class
    }_${participant?.dateOfBirth
      ?.split("-")
      ?.reverse()
      ?.join("")
      ?.slice(0, 4)
      ?.toUpperCase()}`;
    const participantData = {
      name: participant.name,
      dateOfBirth: participant.dateOfBirth,
      className: participant.class,
      enrollmentNo: enrollmentNo,
      parentUid: userAuth.uid,
      parentDocId: parentDetails?.id,
      schoolDetails: schoolDetails,
      homeAddress: null,
      paymentDetails: null,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      subjects: participant.subjects?.map((subject) => {
        const examCode = `P${subject?.slice(0, 1)}O-${uuidv4().substring(
          0,
          5
        )}`?.toUpperCase();
        return {
          subject: subject,
          examCode: examCode,
        };
      }),
    };

    try {
      await setDoc(
        doc(participantsCollectionRef, enrollmentNo),
        participantData
      );
      return participantData;
    } catch (error) {
      throw error;
    }
  };

  const handleFieldChange = (fieldValue, index, fieldName) => {
    const newParticipants = [...participantArray];
    newParticipants[index][fieldName] = fieldValue;
    setParticipantArray(newParticipants);
  };

  const [error, setError] = useState();
  const [amount, setAmount] = useState(0);
  const acknowledgeErrorRef = useRef();
  const subjectErrorRef = useRef();
  const submitErrorRef = useRef();
  const [loadingModule, setLoadingModule] = useState(false);

  const handleSaveAndPay = async (e) => {
    e.preventDefault();
    setError(null);

    if (!acknowledgeChecked) {
      setError((prev) => ({
        ...prev,
        acknowledgement: "Please acknowledge the terms and conditions",
      }));

      acknowledgeErrorRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    let isSubjectError = [];
    let isDobError = [];

    participantArray?.forEach((participant) => {
      if (participant?.subjects?.length === 0) {
        isSubjectError.push(true);
      } else if (
        new Date(participant?.dateOfBirth) >
        new Date().setFullYear(new Date().getFullYear() - 5)
      ) {
        isDobError.push(true);
      }
    });

    if (isSubjectError?.includes(true)) {
      setError((prev) => ({ ...prev, subjects: "Please select subjects" }));
      subjectErrorRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    } else if (isDobError?.includes(true)) {
      setError((prev) => ({
        ...prev,
        submit: "candidate should be at lease 5 years old",
      }));
      submitErrorRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    } else {
      setLoading(true);
      try {
        const partcipantsAdded = await Promise.all(
          participantArray?.map(
            async (participant) => await uploadParticipant(participant)
          )
        );

        const paymentFor = partcipantsAdded?.map((participant) => ({
          participantId: participant.enrollmentNo,
          subjects: participant.subjects.map((subject) => subject.subject),
        }));

        await handlePayment(amount, paymentFor);
      } catch (error) {
        setError((prev) => ({ ...prev, submit: error.message }));
        submitErrorRef?.current?.scrollIntoView({ behavior: "smooth" });
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePayment = async (amount, paymentFor) => {
    const backendUrl = envConfig.backendUrl;
    const {
      data: { key },
    } = await axios.get(`${backendUrl}/api/getkey`);
    const {
      data: { order },
    } = await axios.post(`${backendUrl}/api/checkout`, {
      amount,
      parentId: parentDetails?.id,
      paymentFor: paymentFor,
      currency: country === "IN" ? "INR" : "USD",
      redirectUrl: `${envConfig.appUrl}onboarding/step2`,
    });
    const options = {
      key,
      amount: order.amount,
      currency: country === "IN" ? "INR" : "USD",
      name: envConfig.appFullName,
      description: envConfig.appFullName,
      image: "/img/appLogo.png",
      order_id: order.id,
      callback_url: `${backendUrl}/api/paymentVerification`,
      prefill: {
        name: auth?.currentUser?.displayName,
        email: auth?.currentUser?.email,
        contact: parentDetails?.phone,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#E74C3C",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
    setLoadingModule(true);
  };

  const [cartPositon, setCartPosition] = useState("absolute top-10");
  const cartRef = useRef();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const cart = cartRef.current;

      if (cart && window.scrollY > 260) {
        if (
          document.body.scrollHeight - window.scrollY >
          700 + cart.offsetHeight
        ) {
          setCartPosition("fixed top-24");
        } else {
          setCartPosition("absolute bottom-0");
        }
      } else {
        setCartPosition("absolute top-10");
      }
    });
  }, []);

  return (
    <form className="relative px-3" onSubmit={handleSaveAndPay}>
      {loadingModule && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-black/20 backdrop-blur-sm flex items-center justify-center">
          <div className=" p-20 py-10 bg-white shadow-md rounded-xl text-gray-500 flex flex-col items-center gap-4">
            <ImSpinner2 className="animate-spin w-8 h-8" />
            <p className="text-2xl font-bold ">Please Wait</p>
          </div>
        </div>
      )}
      <div className="onboarding-step max-w-[990px] mt-0 mb-[50px] mx-auto">
        <div
          className={`summary-card for-mobile ${cartPositon} w-[450px] space-y-4`}
          ref={cartRef}
        >
          <Cart
            country={country}
            participantDetailArray={participantArray}
            amount={amount}
            setAmount={setAmount}
          />
        </div>

        <h1 className="text-2xl font-bold ">Participant Details</h1>
        <div className="row mt-4">
          <div className="col-md-3">
            <div className="form-group">
              <label>
                Select No. of Participants<sup>*</sup>
              </label>
              <div className="bg-white border-[1px] border-gray-300 rounded-xl flex items-center justify-between p-2 w-[250px]">
                <button
                  className="p-2 bg-[#E74C3C]/10 text-[#E74C3C] rounded-lg disabled:bg-gray-300 disabled:text-gray-500"
                  disabled={participantArray?.length < 2}
                  onClick={() =>
                    setParticipantArray((prev) => prev.slice(0, -1))
                  }
                >
                  <FaMinus />
                </button>
                <span className="text-base font-bold">
                  {participantArray?.length?.toString().padStart(2, "0")}
                </span>
                <button
                  className="p-2 bg-[#00C707]/10 text-[#00C707] rounded-lg"
                  onClick={() =>
                    setParticipantArray((prev) => [
                      ...prev,
                      {
                        name: "",
                        dateOfBirth: "",
                        subjects: ["maths", "science", "english"],
                        class: "",
                      },
                    ])
                  }
                >
                  <FaPlus />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="onboarding-second-step">
          {participantArray?.map((participant, index) => (
            <div key={index}>
              <div className="flex gap-4 items-center mb-4 row">
                <div className="col-md-6 d-flex align-items-center justify-content-between">
                  <h2 className="text-xl font-bold ">
                    Participant {index + 1} Details
                  </h2>
                  {participantArray.length > 1 && (
                    <>
                      <MdDelete
                        title="Remove"
                        className="text-red-500 cursor-pointer w-6 h-6"
                        onClick={() => {
                          const updatedArray = participantArray.filter(
                            (_, i) => i !== index
                          );
                          setParticipantArray(updatedArray);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-2">
                  <div className="form-group">
                    <label>
                      Participant Name<sup>*</sup>
                    </label>
                    <TextInput
                      placeholderText="Participant Name"
                      inputValue={participant.name}
                      inputName="participantName"
                      // error={errorArray[index].nameError}
                      isRequired={true}
                      handleChange={(e) => {
                        const fieldValue = e.target.value;
                        handleFieldChange(fieldValue, index, "name");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-2">
                  <div className="form-group">
                    <label>
                      Date Of Birth<sup>*</sup>
                    </label>
                    <TextInput
                      type="date"
                      placeholderText="Date Of Birth"
                      inputValue={participant.dateOfBirth}
                      inputName="dob"
                      // error={errorArray[index].nameError}
                      isRequired={true}
                      handleChange={(e) => {
                        const fieldValue = e.target.value;
                        handleFieldChange(fieldValue, index, "dateOfBirth");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-6">
                    <label className="font-bold">
                      Class<sup>*</sup>
                    </label>
                    <div className="select-wrapper">
                      <SelectInput
                        placeholderText="Participant Class"
                        selectOptions={ClassOptions}
                        isRequired={true}
                        // error={errorArray[index].classError}
                        handleChange={(selectedOption) => {
                          const fieldValue = selectedOption.value;
                          handleFieldChange(fieldValue, index, "class");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="flex gap-4">
                      <label className="">
                        Select Subject<sup>*</sup>
                      </label>
                    </div>
                    <div className="flex justify-start gap-5">
                      {subjectsOptions.map((sub) => (
                        <div className="relative" key={sub.id}>
                          <div className="absolute top-[-2rem] right-[-18px]">
                            <Checkbox
                              checkId={`p${index + 1}${sub.value}`}
                              checkName={sub.value}
                              checkValue={sub.value}
                              checked={participant?.subjects?.includes(
                                sub?.value
                              )}
                              handleChange={() => {
                                const newParticipants = [...participantArray];
                                const newSubjects = [...participant?.subjects];
                                participant?.subjects?.includes(sub?.value)
                                  ? newSubjects.splice(
                                      participant?.subjects?.indexOf(
                                        sub?.value
                                      ),
                                      1
                                    )
                                  : newSubjects.push(sub?.value);
                                newParticipants[index].subjects = newSubjects;
                                setParticipantArray(newParticipants);
                              }}
                            />
                          </div>
                          <label
                            htmlFor={`p${index + 1}${sub.value}`}
                            className="flex flex-col items-center justify-center gap-4 cursor-pointer"
                          >
                            <div
                              className={`w-[126px] h-[126px] rounded-2xl flex items-center justify-center subject-card
                                ${
                                  participant?.subjects?.includes(sub.value)
                                    ? "bg-[#F2B010] shadow-[0px_4px_0px_0px_#B88815]"
                                    : "bg-[#ECF0F1] shadow-[0px_4px_0px_0px_#A9A9A9] hover:bg-[#E5E5F1] "
                                }
                              `}
                            >
                              <img src={sub.icon} alt={sub.label} />
                            </div>
                            <div className="font-bold text-[16px] text-[#34495E]">
                              {sub.label}
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>{" "}
                    {error?.subjects && (
                      <div className="text-red-500 mb-2" ref={subjectErrorRef}>
                        {error?.subjects}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {index !== participantArray.length - 1 && (
                <div className="h-[1px] bg-gray-300 w-full mt-2 mb-8" />
              )}
            </div>
          ))}

          <div className="mt-10">
            {error && (
              <div className="text-red-500 mb-2" ref={submitErrorRef}>
                {error?.submit}
              </div>
            )}
            <div className="flex gap-2 items-center">
              {/* <Button
                loading={loading}
                text="Next"
                version="secondary"
                type="submit"
              /> */}
            </div>
          </div>
        </div>
        <div className="my-8 imp-inst">
          <Step1Instructions
            checked={acknowledgeChecked}
            setChecked={setAcknowledgeChecked}
            acknowledgeErrorRef={acknowledgeErrorRef}
            error={error?.acknowledgement}
          />
        </div>
      </div>
      <div
        className={`summary-card desktop-view w-[450px] z-40 right-[10%] -top-10 h-full absolute `}
      >
        <div
          className={`summary-card ${cartPositon} w-[450px] space-y-4`}
          ref={cartRef}
        >
          <Cart
            country={country}
            participantDetailArray={participantArray}
            amount={amount}
            setAmount={setAmount}
          />
          <Button
            loading={loading}
            type="submit"
            text="Save and Pay"
            version="secondary"
            // handleClick={handleSaveAndPay}
          />
        </div>
      </div>
    </form>
  );
};

export default Step1;
