import React, { useEffect, useState } from "react";
import { countDown } from "../../../assets/js/countDown";
const CountDown = ({ date }) => {
  const [examDateTime, setExamDateTime] = useState();
  useEffect(() => {
    setExamDateTime(new Date(date).getTime());
  }, [date]);

  // const currentUserParticipants = query(participantsCollectionRef, where("uid", "==", auth.currentUser.uid))
  // useEffect(() => {
  //     try {
  //         onSnapshot(currentUserParticipants, snap => {
  //             let data = snap.docs.map(doc => {
  //                 return {...doc.data(), id: doc.id}
  //             })
  //             let examDateTime = data.map(item => item?.examDateTime?.seconds * 1000)
  //             examDateTime = Math.min(...examDateTime)
  //             setExamDateTime(examDateTime)
  //         })
  //     } catch (error) {
  //         console.log(error)
  //     }
  // },[])

  // const [examDateTime, setExamDateTime] = useState()
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const { days, hours, minutes, seconds } = countDown(examDateTime);
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, [examDateTime]);

  return (
    <div className="instructions-bg">
      <div className="inner-container">
        <h3>
          Chalange
          <br />
          Starts in <img src="/img/blue-arrow.png" alt="img" />
        </h3>
        <div className="detail flex gap-4">
          <div className="item flex flex-col justify-center items-center">
            <span className="number">{days < 10 ? `0${days}` : days}</span>
            <span className="text">days</span>
          </div>
          <div className="item flex flex-col justify-center items-center">
            <span className="number">{hours < 10 ? `0${hours}` : hours}</span>
            <span className="text">hrs</span>
          </div>
          <div className="item flex flex-col justify-center items-center">
            <span className="number">
              {minutes < 10 ? `0${minutes}` : minutes}
            </span>
            <span className="text">mins</span>
          </div>
          <div className="item flex flex-col justify-center items-center">
            <span className="number">
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>
            <span className="text">secs</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDown;
