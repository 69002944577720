import React from "react";

const SchoolLogo = ({ schoolDetails }) => {
  if (schoolDetails)
    return (
      <div className="col-span-2 flex items-center justify-center gap-5">
        <div className="bg-gray-400 w-24 h-24 overflow-hidden flex items-center justify-center rounded-full flex-shrink-0">
          {schoolDetails?.schoolLogoUrl && (
            <img
              src={schoolDetails?.schoolLogoUrl}
              alt="schoolLogo"
              className="h-full w-full"
            />
          )}
        </div>

        <div className="flex flex-col">
          <h2 className="text-2xl font-semibold">
            {schoolDetails?.schoolName}
          </h2>
          <p className="text-sm">
            {schoolDetails?.schoolAddress.address},{" "}
            {schoolDetails?.schoolAddress.city},{" "}
            {schoolDetails?.schoolAddress.state},{" "}
            {schoolDetails?.schoolAddress.country},{" "}
            {schoolDetails?.schoolAddress.pin}
          </p>
        </div>
      </div>
    );
};

export default SchoolLogo;
